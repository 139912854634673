@import "./vars-mixins";

// Mobile only
@include breakpoint-down(sm) {
  // Mob side menu
  .navbar-toggler,
  .navbar-toggler-icon {
    outline: none;
  }
  .top-menu {
    &__container {
      position: fixed;
      width: 0;
      height: 100%;
      top: 50px;
      left: 0;
      padding: 20px 15px;
      background-color: var(--gray-dark);
       -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      visibility: hidden;
      overflow: hidden;
      transition: transform 0.3s ease 0s, visibility .3s ease 0s;
      -webkit-transition: transform 0.3s ease 0s, visibility .3s ease 0s;

      &.open {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
        overflow-y: auto;
        width: 100%;
      }
    }
    &__item {
      margin-bottom: 12px
    }
    &__link {
      color: #fff !important;//boostrap override
      font-size: 20px;
    }
    &__icon {
      width: 50px;
      font-size: 28px;
      color: #fff;
    }
    &__logo {
      position: absolute;
      left: 50%;
      margin-left: -17px;
    }
    &__profile {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}// mobile only

// Mobile-first
.navbar {
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
}

.top-menu {
  margin: 0 auto;
  font-size: 1rem;
  //color: #fff;
  &__logo {
    z-index: 99;
    $size: 35px;
    width: $size;
    height: $size;
    img {
      width: $size;
      height: $size;
    }
  }
  &__item {
    &, a {outline: none;}
    &.active {
      font-weight: bold;
    }
  }

  &__profile-icon {
    margin-right: 5px;
  }
  &__dropdown-item {
    font-weight: 300;
    &:hover {
      background-color: #efefef;
    }

    &.disabled,
    &:disabled {
      color: #6c757d;
      background-color: transparent;
      opacity: .5;
    }
  }
  &__small-icon {
    color: rgba(0, 0, 0, .5);
    margin: 0 15px 0 8px;
  }

}//.top-menu

// search btn, hidden on desk
.search-mobile-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 50px;
  height: 45px;
  background-color: transparent;
  z-index: 9999;
  @include breakpoint-up(md){
    display: none;
  }
  .search-filter-navbar {
    cursor: pointer;
    color: black;
    font-size: 18px;
  }
}//search-mobile-navbar

@include breakpoint-up(md){
  .top-menu {
    width: 100%;
    justify-content: center;
    &__container {
      padding: 0 10px;
    }
    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 5px;
      font-size: 12px;
    }
    &__icon {
      width: auto;
      margin-bottom: 3px;
      font-size: 18px;
      color: rgba(0, 0, 0, .5);
    }

    &__profile-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__profile-icon {
      display: none;
    }
  }//top-menu

  .mainmenu .collapse ul ul li {
    position: relative
  }
  .mainmenu .collapse ul ul li:hover > ul {
    display: block
  }
  .mainmenu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none
  }
  .mainmenu .collapse ul ul ul li {
    position: relative
  }
  .mainmenu .collapse ul ul ul li:hover ul {
    display: block
  }
  .mainmenu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1
  }
}//md

@include breakpoint-up(lg) {
  .top-menu {
    &__container {
      padding: 0 15px;
    }
    &__link {
      font-size: 14px;
    }
    &__icon {
      margin-bottom: 0;
    }
  }//top-menu
}//lg

@include breakpoint-up(xl) {
  .top-menu {
    &__link {
      display: block;
      font-size: 16px;
    }
    &__icon {
      margin-right: 4px;
      font-size: 16px;
    }
    &__profile-inner {
      flex-direction: row;
    }
    &__profile-icon {
      display: block;
    }
  }//top-menu
}

// news & notifications - not used
//.notifications {
//  background-color: transparent;
//  font-size: 24px;
//  margin-right:15px;
//  &__container {
//    display: none;
//    width: 380px;
//    position: absolute;
//    top: 42px;
//    right: -7px;
//    background: white;
//    border: solid 1px #c6c6c6;
//    box-shadow: 0 3px 8px rgba(0, 0, 0, .20);
//    z-index: 999;//0
//    cursor: default;
//  }
//  &__inner {
//    min-height: 210px;
//    max-height: 280px;
//    overflow-y: auto;
//  }
//  &__alert {
//    display:inline-block;
//    position:relative;
//    padding: 1px 5px;
//    color:black;
//    cursor: pointer;
//  }
//  &__alert-badge {
//    background-color: red;
//    border-radius: 10px;
//    color: white;
//    font-weight: 400;
//    padding: 0px 5px;
//    font-size: 11px;
//    position: absolute;
//    top: 0;
//    right: 0;
//  }
//  &__title {
//    color: #545b61;
//    background: #f0f3f4;
//    font-weight: bold;
//    font-size: 13px;
//    padding: 8px;
//    margin: 0;
//    border-bottom: solid 1px rgba(100, 100, 100, .30);
//  }
//  &__item {
//    padding: 5px 10px 13px;
//    width: 100%;
//    border-bottom: solid 1px #ebeff3;
//    background: #f8f9fa94;
//    .far {
//      padding-right:8px;
//      font-size:15px;
//      color:#4f4f4f
//    }
//    .badge {
//      font-size: 13px;
//      color: #fff;
//      &.green {background-color: #39c150}
//      &.pink {background-color: #ff00b3}
//      &.vio {background-color: #782f90}
//    }
//  }
//  &__item-text {
//    padding-top:5px;
//    font-size:15px;
//    font-weight:500;
//    color:#4a4a4a;
//    margin: 0;
//  }
//  &__see-all {
//    background: #F6F7F8;
//    padding: 8px;
//    font-size: 12px;
//    font-weight: bold;
//    border-top: solid 1px rgba(100, 100, 100, .30);
//    text-align: center;
//    a {
//      color: #3b5998;
//      &:hover {
//        background: #F6F7F8;
//        color: #3b5998;
//        text-decoration: underline;
//      }
//    }
//  }
//}//notifications
