@import "./vars-mixins";

// Search sidebar - different for all pages
.navbar-search { // see also .collapse in common.scss
  min-height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
  padding-top: 60px;
  padding-left: 0;
  @include breakpoint-up(md) {
    padding-top: 78px;
  }
  @include breakpoint-up(lg) {
    padding-top: 80px;
  }
  @include breakpoint-up(xl) {
    padding-top: 57px;
  }
  &.show {
    @include breakpoint-up(md) {
      width: 60% !important;// TODO replace in all templates "col-xl-3 col-lg-4 navbar-search" -> "col-lg-6 col-md-8 navbar-search"
    }
    @include breakpoint-up(lg) {
      width: 40% !important;// TODO
      max-width: 520px;
    }
  }//show

  .card.search {
    height: calc(100vh - 61px);
    border-radius: 0;
    border-top: 1px solid rgba(0, 0, 0, .05);
  }

  .card-header button i {
    display: none;
    -webkit-transition: display .2s ease;
    transition: display .2s ease;
  }

  &:not(.show) .card-header .fa-bars,
  &:not(.show) .card-header .fa-search {//button i:first-of-type {
    display: block;
  }

  &.show .card-header .fa-times {//button i:nth-of-type(2) {
    display: block;
  }

  @include breakpoint-up(md) {
    .card.search {
      height: 100%;
    }
  }//md

  @include max(767) {
    .card.search {
      -webkit-overflow-scrolling: touch;
      .card-body {
        padding-bottom: 140px;// TODO fix for iphone
      }
      .card-footer-search {// TODO fix for iphone
        position: fixed !important;
        z-index: 99;
        height: 130px;
        padding-top: 10px;
        align-items: flex-start;
      }
    }

    &.collapse,
    &.collapse.show {
      padding-right: 0;
      width: 100%;
    }
    &.show {
      position: fixed;
      padding-top: 46px;
    }
    &.collapse:not(.show) .card.search .card-header,
    &.collapse:not(.show) .card.search,
    &.collapse:not(.show) .card.search .card-body {
      background-color: transparent;
      border: none;
    }
    &.collapse:not(.show) .card.search {
      height: 80px;
      border: none;
    }
  }//max(1199)
}//navbar-search


.card.search {
  background-color: #f8f9fa;

  .collapse:not(.show) & .card-footer-search {
    display: none;
  }

  .card-body {
    background-color: #f8f9fa;
    overflow-y: auto;
    padding-bottom: 100px;
    .collapse:not(.show) & {
      display: none;
    }

    .form-row label {
      white-space: nowrap;
    }
  }

  .form-control {
    background-color: transparent;
  }

  .card-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;
    padding: 0px 15px;
    border-radius: 0;
    font-size: 17px;

    .nav-link:not(.active) {
      color: #495057;
    }
    .item-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      ul {
        height: 100%;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        li.nav-item {
          height: auto;
          margin-right: 7px;
        }
      }
    }

    .card-header-item {
      &:hover {
        text-decoration: none;
      }

      &.nav-link:not(.active) {
        color: #444655;
        padding: 5px 20px;
        -webkit-transition: border-radius 300ms ease-in-out;
        transition: border-radius 300ms ease-in-out;
      }

      &.nav-link:not(.active):hover {
        border-radius: 20px;
      }

      &.active {
        border: 1px solid #444655;
        background-color: #444655;
        border-radius: 20px;
        padding: 5px 20px;
        color: white;
      }
    }
  }//.card-header

  .card-footer-search {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 80px;
    width: 100%;
    background-color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
    border-top: 1px solid rgba(0, 0, 0, .125);

    .tab-pane.search {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .tab-pane.search.fade:not(.show) {
      display: none;
    }

    .btn {
      background-color: #007BFF;
      border-color: #007BFF;
      padding: .375rem 1.75rem;
      font-size: 1.1rem;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        -webkit-box-shadow: inset 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
      }

      i {
        margin-right: 5px;
      }

      &.btn-reset {
        background-color: #A8AABC;
        border-color: #A8AABC;
        color: white;
      }
      &.btn-primary.focus,
      &.btn-primary:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }//btn
    @include min(1024) {
      .tab-pane.search .btn {
        min-width: 140px;
      }
    }

  }//card-footer-search
}//.card.search





