.navbar-search {
  min-height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
  padding-top: 60px;
  padding-left: 0;
}

@media all and (min-width: 768px) {
  .navbar-search {
    padding-top: 78px;
  }
}

@media all and (min-width: 992px) {
  .navbar-search {
    padding-top: 80px;
  }
}

@media all and (min-width: 1200px) {
  .navbar-search {
    padding-top: 57px;
  }
}

@media all and (min-width: 768px) {
  .navbar-search.show {
    width: 60% !important;
  }
}

@media all and (min-width: 992px) {
  .navbar-search.show {
    width: 40% !important;
    max-width: 520px;
  }
}

.navbar-search .card.search {
  height: calc(100vh - 61px);
  border-radius: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.navbar-search .card-header button i {
  display: none;
  -webkit-transition: display .2s ease;
  transition: display .2s ease;
}

.navbar-search:not(.show) .card-header .fa-bars,
.navbar-search:not(.show) .card-header .fa-search {
  display: block;
}

.navbar-search.show .card-header .fa-times {
  display: block;
}

@media all and (min-width: 768px) {
  .navbar-search .card.search {
    height: 100%;
  }
}

@media all and (max-width: 767px) {
  .navbar-search .card.search {
    -webkit-overflow-scrolling: touch;
  }
  .navbar-search .card.search .card-body {
    padding-bottom: 140px;
  }
  .navbar-search .card.search .card-footer-search {
    position: fixed !important;
    z-index: 99;
    height: 130px;
    padding-top: 10px;
    align-items: flex-start;
  }
  .navbar-search.collapse, .navbar-search.collapse.show {
    padding-right: 0;
    width: 100%;
  }
  .navbar-search.show {
    position: fixed;
    padding-top: 46px;
  }
  .navbar-search.collapse:not(.show) .card.search .card-header,
  .navbar-search.collapse:not(.show) .card.search,
  .navbar-search.collapse:not(.show) .card.search .card-body {
    background-color: transparent;
    border: none;
  }
  .navbar-search.collapse:not(.show) .card.search {
    height: 80px;
    border: none;
  }
}

.card.search {
  background-color: #f8f9fa;
}

.collapse:not(.show) .card.search .card-footer-search {
  display: none;
}

.card.search .card-body {
  background-color: #f8f9fa;
  overflow-y: auto;
  padding-bottom: 100px;
}

.collapse:not(.show) .card.search .card-body {
  display: none;
}

.card.search .card-body .form-row label {
  white-space: nowrap;
}

.card.search .form-control {
  background-color: transparent;
}

.card.search .card-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  padding: 0px 15px;
  border-radius: 0;
  font-size: 17px;
}

.card.search .card-header .nav-link:not(.active) {
  color: #495057;
}

.card.search .card-header .item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.card.search .card-header .item-container ul {
  height: 100%;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card.search .card-header .item-container ul li.nav-item {
  height: auto;
  margin-right: 7px;
}

.card.search .card-header .card-header-item:hover {
  text-decoration: none;
}

.card.search .card-header .card-header-item.nav-link:not(.active) {
  color: #444655;
  padding: 5px 20px;
  -webkit-transition: border-radius 300ms ease-in-out;
  transition: border-radius 300ms ease-in-out;
}

.card.search .card-header .card-header-item.nav-link:not(.active):hover {
  border-radius: 20px;
}

.card.search .card-header .card-header-item.active {
  border: 1px solid #444655;
  background-color: #444655;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
}

.card.search .card-footer-search {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 80px;
  width: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card.search .card-footer-search .tab-pane.search {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card.search .card-footer-search .tab-pane.search.fade:not(.show) {
  display: none;
}

.card.search .card-footer-search .btn {
  background-color: #007BFF;
  border-color: #007BFF;
  padding: .375rem 1.75rem;
  font-size: 1.1rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card.search .card-footer-search .btn:hover {
  -webkit-box-shadow: inset 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
}

.card.search .card-footer-search .btn i {
  margin-right: 5px;
}

.card.search .card-footer-search .btn.btn-reset {
  background-color: #A8AABC;
  border-color: #A8AABC;
  color: white;
}

.card.search .card-footer-search .btn.btn-primary.focus, .card.search .card-footer-search .btn.btn-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media all and (min-width: 1024px) {
  .card.search .card-footer-search .tab-pane.search .btn {
    min-width: 140px;
  }
}

@media all and (max-width: 766px) {
  .navbar-toggler,
  .navbar-toggler-icon {
    outline: none;
  }
  .top-menu__container {
    position: fixed;
    width: 0;
    height: 100%;
    top: 50px;
    left: 0;
    padding: 20px 15px;
    background-color: var(--gray-dark);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: hidden;
    overflow: hidden;
    transition: transform 0.3s ease 0s, visibility .3s ease 0s;
    -webkit-transition: transform 0.3s ease 0s, visibility .3s ease 0s;
  }
  .top-menu__container.open {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
    overflow-y: auto;
    width: 100%;
  }
  .top-menu__item {
    margin-bottom: 12px;
  }
  .top-menu__link {
    color: #fff !important;
    font-size: 20px;
  }
  .top-menu__icon {
    width: 50px;
    font-size: 28px;
    color: #fff;
  }
  .top-menu__logo {
    position: absolute;
    left: 50%;
    margin-left: -17px;
  }
  .top-menu__profile {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.navbar {
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
}

.top-menu {
  margin: 0 auto;
  font-size: 1rem;
}

.top-menu__logo {
  z-index: 99;
  width: 35px;
  height: 35px;
}

.top-menu__logo img {
  width: 35px;
  height: 35px;
}

.top-menu__item, .top-menu__item a {
  outline: none;
}

.top-menu__item.active {
  font-weight: bold;
}

.top-menu__profile-icon {
  margin-right: 5px;
}

.top-menu__dropdown-item {
  font-weight: 300;
}

.top-menu__dropdown-item:hover {
  background-color: #efefef;
}

.top-menu__dropdown-item.disabled, .top-menu__dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
  opacity: .5;
}

.top-menu__small-icon {
  color: rgba(0, 0, 0, 0.5);
  margin: 0 15px 0 8px;
}

.search-mobile-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 50px;
  height: 45px;
  background-color: transparent;
  z-index: 9999;
}

@media all and (min-width: 768px) {
  .search-mobile-navbar {
    display: none;
  }
}

.search-mobile-navbar .search-filter-navbar {
  cursor: pointer;
  color: black;
  font-size: 18px;
}

@media all and (min-width: 768px) {
  .top-menu {
    width: 100%;
    justify-content: center;
  }
  .top-menu__container {
    padding: 0 10px;
  }
  .top-menu__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
    font-size: 12px;
  }
  .top-menu__icon {
    width: auto;
    margin-bottom: 3px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
  }
  .top-menu__profile-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .top-menu__profile-icon {
    display: none;
  }
  .mainmenu .collapse ul ul li {
    position: relative;
  }
  .mainmenu .collapse ul ul li:hover > ul {
    display: block;
  }
  .mainmenu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none;
  }
  .mainmenu .collapse ul ul ul li {
    position: relative;
  }
  .mainmenu .collapse ul ul ul li:hover ul {
    display: block;
  }
  .mainmenu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1;
  }
}

@media all and (min-width: 992px) {
  .top-menu__container {
    padding: 0 15px;
  }
  .top-menu__link {
    font-size: 14px;
  }
  .top-menu__icon {
    margin-bottom: 0;
  }
}

@media all and (min-width: 1200px) {
  .top-menu__link {
    display: block;
    font-size: 16px;
  }
  .top-menu__icon {
    margin-right: 4px;
    font-size: 16px;
  }
  .top-menu__profile-inner {
    flex-direction: row;
  }
  .top-menu__profile-icon {
    display: block;
  }
}

:root {
  --searchFilterVisible: 80px;
  --searchFilterVisibleTablet: 60px;
}

html,
body {
  height: 100%;
  padding: 0;
}

footer#stickyFooter {
  position: fixed;
  bottom: 0;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  left: 0;
  z-index: 99;
  background-color: #252121;
  opacity: 0.8;
  -webkit-transition: ease 500ms;
  -moz-transition: ease 500ms;
  -ms-transition: ease 500ms;
  -o-transition: ease 500ms;
  transition: ease 500ms;
}

footer#stickyFooter.hideForSearch {
  z-index: 0;
}

footer#stickyFooter div.nospaceCenteredLeft {
  margin-right: 88px;
}

@media all and (max-width: 1399px) {
  footer#stickyFooter div.nospaceCenteredLeft {
    margin-right: 36px;
  }
}

@media all and (max-width: 767px) {
  footer#stickyFooter {
    display: none;
  }
}

footer#stickyFooter.py-3 {
  padding-bottom: 0.15rem !important;
  padding-top: 0.15rem !important;
}

footer#stickyFooter span {
  cursor: default;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.5px;
}

footer#stickyFooter span i {
  font-size: 13px;
  font-weight: bold;
}

footer#stickyFooter.relative {
  position: fixed;
}

.navbar-search.collapse {
  right: -100%;
  left: unset;
  -webkit-transition: right .3s ease;
  transition: right .3s ease;
}

.navbar-search.collapse.show {
  right: 0;
  left: unset;
}

@media all and (min-width: 768px) {
  .navbar-search.collapse {
    left: 0;
    right: unset;
    transform: translateX(calc(-100% + 80px));
    -webkit-transition: transform .3s ease;
    transition: transform .3s ease;
  }
  .navbar-search.collapse.show {
    display: block;
    transform: translateX(0);
    left: 0;
    right: unset;
  }
  .navbar-search.collapse:not(.show) {
    display: block;
  }
  .navbar-search.collapse:not(.show):hover {
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
  .navbar-search.collapse:not(.show) .card.search .card-body,
  .navbar-search.collapse:not(.show) .card.search .card-footer-search {
    display: none;
  }
}

.navbar-search.collapsing {
  height: auto;
  right: -100%;
  left: unset;
  -webkit-transition: right .3s ease;
  transition: right .3s ease;
}

@media all and (min-width: 768px) {
  .navbar-search.collapsing {
    width: 60%;
    right: unset;
    left: 0;
    transform: translateX(-100%);
    -webkit-transition: transform .3s ease;
    transition: transform .3s ease;
  }
}

@media all and (min-width: 992px) {
  .navbar-search.collapsing {
    width: 40%;
    max-width: 520px;
  }
}

.inline {
  display: inline-block;
}

[data-copy] {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.mobile {
  display: none;
}

.mt-20 {
  margin-top: 20px;
}

.maincontainer {
  top: 61px;
}

@media all and (min-width: 768px) {
  .maincontainer {
    top: 80px;
  }
}

@media all and (min-width: 1200px) {
  .maincontainer {
    top: 60px;
  }
}

.white {
  color: white !important;
}

.failed-comment-icon {
  position: absolute;
  left: 64px;
  top: 5px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.beta-label {
  color: red;
  font-weight: 400 !important;
  font-size: 11px !important;
  margin-left: 7px;
  text-transform: capitalize;
}

.text-centered {
  text-align: center !important;
}

.text-orange {
  color: #FF9800 !important;
}

.navbar.fixed-bottom {
  height: 25px;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: .9rem;
  color: #4a4a4a;
}

.btn-violet {
  background-color: blueviolet !important;
  border-color: blueviolet !important;
}

.btn-violet:active:focus {
  box-shadow: 0 0 0 0.2rem #8a2be291 !important;
}

.dataTables_length {
  margin-left: 20px;
}

.dataTables_filter {
  margin-right: 20px;
}

.dataTables_wrapper div.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}

table.dataTable {
  padding: 20px;
}

.badge-perc-mix {
  position: absolute;
  font-size: 9px;
  margin: 4px 0px 0px 1px;
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.orders-total-badge {
  cursor: pointer;
  color: white;
  background-color: #da396e;
  margin-left: 5px;
  font-size: 11px;
  letter-spacing: 1px;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

.orders-total-badge-ok {
  cursor: pointer;
  color: white;
  background-color: #0f9c0f;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-ko {
  cursor: pointer;
  color: white;
  background-color: red;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-orange {
  cursor: pointer;
  color: white;
  background-color: orange;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-violet {
  cursor: pointer;
  color: white;
  background-color: blueviolet;
  font-size: 11px;
  letter-spacing: 1px;
}

.green {
  color: #28A745;
}

.yellow {
  color: #FFC107;
}

.red {
  color: #DC3545;
}

.btn-orange {
  background-color: #FF9800 !important;
  border-color: #FF9800 !important;
  color: white;
}

.btn-orange:hover {
  color: white !important;
  box-shadow: 0 2px 5px #FF9800;
}

.btn-danger:hover {
  color: white !important;
  box-shadow: 0 2px 5px #ff7476;
}

.btn-fedex {
  background-color: #4e148c !important;
  border-color: #4e148c !important;
  color: white;
}

.btn-fedex:hover {
  background-color: #4e148cc7 !important;
  border-color: #4e148cc7 !important;
  color: white;
}

.orders-total-badge-info {
  cursor: pointer;
  color: white;
  background-color: #2196f3;
  margin-left: 5px;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-brown {
  cursor: pointer;
  color: white;
  background-color: #4d2a11;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-blue {
  cursor: pointer;
  color: white;
  background-color: #0018ef;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-dark-yellow {
  cursor: pointer;
  color: white;
  background-color: #7a7301;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-deep-blue {
  cursor: pointer;
  color: white;
  background-color: #001152;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-grey {
  cursor: pointer;
  color: white;
  background-color: rgba(68, 68, 68, 0.96);
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-dark-pink {
  cursor: pointer;
  color: white;
  background-color: #a30250;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-dark-green {
  cursor: pointer;
  color: white;
  background-color: #548703;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-dark-purple {
  cursor: pointer;
  color: white;
  background-color: #5b1a9c;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-dark-gold {
  cursor: pointer;
  color: white;
  background-color: #737250;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-dark-turquoise {
  cursor: pointer;
  color: white;
  background-color: #167f73;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-dark-red {
  cursor: pointer;
  color: white;
  background-color: #700404;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-light-red {
  cursor: pointer;
  color: white;
  background-color: #5e5353;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-mint {
  cursor: pointer;
  color: white;
  background-color: #0cb362;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-light-mint {
  cursor: pointer;
  color: white;
  background-color: #697d73;
  font-size: 11px;
  letter-spacing: 1px;
}

.orders-total-badge-processing {
  background-color: #ffcc3e !important;
  border-color: #ffcc3e !important;
  color: white;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-home {
  background: #545b621a;
  color: #4a4a4a;
  border: 1px solid #22222229;
}

.btn-home:hover {
  color: white;
  background: black;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.effect7 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.effect7:before,
.effect7:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-radius: 100px / 10px;
}

.effect7:after {
  right: 10px;
  left: auto;
  -webkit-transform: skew(8deg) rotate(3deg);
  transform: skew(8deg) rotate(3deg);
}

/* Bootstrap Theming */
.form-control {
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

label {
  font-weight: 600;
}

.btn-outline-disabled {
  opacity: 0.3 !important;
  border: 1px solid #ccc;
}

.btn-outline-orange {
  border: 1px solid #FF9800;
  color: #FF9800;
  background-color: transparent !important;
}

.bg-pesca {
  background-color: #ff7476;
}

.btn-pesca {
  background-color: #ff7476;
  color: white;
}

.btn-pesca:hover {
  color: white;
  box-shadow: 0 2px 5px #ff7476;
}

.btn-info:hover {
  color: white;
  box-shadow: 0 2px 5px #14a2b8;
  background-color: #14a2b8 !important;
  border-color: #14a2b8 !important;
}

.btn-primary {
  background-color: #3696ff !important;
  border-color: #3696ff !important;
}

.btn-primary:hover {
  color: white;
  box-shadow: 0 2px 5px #3696ff;
  background-color: #3696ff !important;
  border-color: #3696ff !important;
}

.btn-outline-pesca {
  border-color: #ff7476;
  color: #ff7476;
  background-color: transparent !important;
}

.text-pesca {
  color: #ff7476;
}

.text-big {
  font-size: 34px;
  font-weight: 300;
}

@media screen and (min-width: 992px) {
  .text-big {
    font-size: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .text-big {
    font-size: 50px;
  }
}

/* Title Page */
.title-page h3 {
  display: inline-block;
}

.title-page span {
  display: inline-block;
  width: 0px;
  height: 30px;
  border-left: 1px solid #c7c7c7;
  margin-left: 10px;
}

.title-page nav {
  display: inline-block;
}

/* Breadcrumbs */
.breadcrumb {
  background-color: transparent;
  font-size: 14px;
  margin-bottom: 0;
}

.breadcrumb a {
  color: #737373;
  text-decoration: none;
}

.breadcrumb a:hover {
  color: black;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #a7adb3;
}

.group_breadcrumb {
  text-align: left;
  display: inline-block;
}

/* Home Cards */
.home-card .card-up {
  overflow: hidden;
  height: 120px;
  border-radius: .3rem .3rem 0 0;
  background: transparent;
}

.home-card .avatar {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin: -35px auto 0;
  overflow: hidden;
  border: 2px solid #222222;
  background: white;
}

.home-card {
  text-align: center;
}

.home-card h2 {
  color: red;
  font-size: 38px;
  margin-top: 12px;
}

.border-top-dashed {
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}

.border-bottom-dashed {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.border-left-dashed {
  border-left: 1px dashed rgba(0, 0, 0, 0.1);
}

.border-left-dotted {
  border-left: 1px dotted rgba(0, 0, 0, 0.1);
}

.no-padding {
  padding-right: 0;
  padding-left: 0;
}

/* Cards */
.progress {
  height: 7px;
}

.card-spacer {
  margin-top: 40px;
  background: transparent;
}

.card-body {
  padding-bottom: 8px;
  padding-top: 8px !important;
}

.card-header {
  font-size: 20px;
  background-color: white;
}

.card-footer {
  background-color: white;
}

.card.disabled {
  opacity: 0.4;
}

/* Totals Card */
.totals h2 {
  font-weight: 300 !important;
  font-size: 38px;
}

@media screen and (min-width: 992px) {
  .totals h2 {
    font-size: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .totals h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1400px) {
  .totals h2 {
    font-size: 50px;
  }
}

.totals .brand-chart-number {
  margin-right: 5px;
}

.totals .brand-chart-percentage,
.totals .brand-chart-number {
  display: inline-block;
  width: 100%;
}

/* Brand Cards */
.brand-card h2 {
  font-weight: 300;
  white-space: nowrap;
}

@media all and (min-width: 1200px) {
  .brand-card h2 {
    font-size: 1.6rem;
  }
}

@media (min-width: 1199px) and (max-width: 1430px) {
  .brand-card h2 {
    font-size: 1.45rem;
  }
}

@media all and (min-width: 1600px) {
  .brand-card h2 {
    font-size: 2rem;
  }
}

.brand-card h5 {
  font-weight: 300;
}

.brand-chart-percentage {
  font-size: 11px;
}

.brand-chart-percentage b {
  font-weight: 600;
}

.brand-chart-percentage .percentage-item {
  margin-top: 3px;
}

.brand-chart-percentage .percentage-item p {
  display: inline;
}

.brand-chart-percentage .percentage-item p span {
  display: inline-block;
}

.brand-chart-percentage .percentage-item .arrow-val {
  white-space: nowrap;
}

/* Stock */
.pagination {
  float: right;
}

table.dataTable.no-footer,
table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 0 !important;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background-image: none !important;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 7px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  margin-right: 3px;
  top: 7px;
}

div.dataTables_wrapper div.dataTables_length label {
  margin-top: 15px;
}

div.dataTables_wrapper div.dataTables_filter {
  margin-top: 15px;
}

.table td,
.table th {
  padding: .5rem;
}

/* Popover */
.popover {
  max-width: none;
}

.divTable {
  width: 100%;
}

.divTableRow {
  display: table-row;
}

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
}

.divTableCell,
.divTableHead {
  display: table-cell;
  padding: 3px 10px;
  border-bottom: 1px solid #b9b9b9;
  color: #383838;
  font-size: .9em;
}

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
}

.stock-thumb {
  border: 1px solid #dadada;
}

.stock-thumb-title {
  position: absolute;
  top: 0;
  color: white;
  padding: 2px 3px 2px 2px;
  background: #5599CA;
  border-radius: 5px;
}

.stock-thumb-title-prod {
  position: absolute;
  top: 0;
  color: white;
  padding: 2px 3px 2px 2px;
  background: #B73700;
  border-radius: 5px;
}

.stock-thumb-env-prod {
  color: white;
  background: #B73700;
  padding: 3px 5px 3px 5px;
  border-radius: 8px;
  font-size: 12px;
  width: 100px;
  text-align: center;
  font-weight: 400;
  margin-left: 10px;
}

.stock-thumb-env {
  color: white;
  background: #5599CA;
  padding: 3px 5px 3px 5px;
  border-radius: 8px;
  font-size: 12px;
  width: 100px;
  text-align: center;
  font-weight: 400;
  margin-left: 10px;
}

.stock-thumb-light {
  color: grey;
  font-size: 12px;
  width: 100px;
  text-align: center;
  font-weight: 400;
}

/* Card Gradients */
.home-card .card-up {
  overflow: hidden;
  height: 120px;
  border-radius: .3rem .3rem 0 0;
  background: transparent;
}

.play-button-wrapper {
  position: absolute;
  top: 1.5rem;
  right: 15px;
}

.group_data_range {
  text-align: right;
}

.group_data_range .dashboard-top-buttons {
  font-size: 1.2rem;
}

.stock-results.table-responsive,
.crm-results.table-responsive {
  min-height: calc(100vh - 60px - 1.5rem);
}

/* STORE LOCATOR */
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #dedede !important;
}

table.dataTable tbody tr.selected,
table.dataTable tbody th.selected,
table.dataTable tbody td.selected {
  color: black !important;
}

.add-abs-btn,
.add-abs-btn:focus {
  font-size: 25px;
  border-radius: 50%;
  color: #fff !important;
  background-color: #2196F3 !important;
  width: 56px;
  height: 56px;
  -webkit-box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
  box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
  margin: 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.add-abs-btn:hover {
  background-color: #2196F3;
  text-decoration: none;
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.add-abs-btn.active,
.add-abs-btn:active {
  text-decoration: none;
  color: #a7a7a7;
  background-color: #f7f7f7;
  border-color: white;
}

table.dataTable tr.selected td.select-checkbox:after,
table.dataTable tr.selected th.select-checkbox:after {
  content: '\f00c' !important;
  font-family: FontAwesome !important;
  margin-left: -5px !important;
  text-shadow: none !important;
}

#store-pagination ul {
  margin-bottom: 0;
}

.bg-absolute {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 61px);
  z-index: 98;
  opacity: 0.5;
  min-height: 100%;
}

.bg-absolute.collapsed {
  display: none;
}

.bg-absolute.collapsed.btn-close {
  display: none;
}

.bg-absolute .btn-close {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.crm-results {
  width: 100%;
}

@media all and (min-width: 768px) {
  .crm-results {
    margin-left: auto;
    width: calc(100% - var(--searchFilterVisible));
  }
}

.crm-results .card-header {
  min-height: 60px;
}

.plus-btn-wrapper {
  position: fixed;
  bottom: 45px;
  right: 9px;
  z-index: 997;
}

.back-to-top {
  background: none;
  margin: 0;
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 40px;
  height: 40px;
  z-index: 100;
  display: none;
  text-decoration: none;
  color: black;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.back-to-top i {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
}

.back-to-top i:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.dataTable .btn-outline-primary.focus,
.dataTable .btn-outline-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* DATE PICKER */
.daterangepicker {
  background-color: #FAFAFA;
  padding: 8px;
  border-radius: 2px;
}

.daterangepicker .calendar {
  padding: 8px 8px 0;
  margin-bottom: 0;
}

.daterangepicker .calendar-table {
  background-color: #FAFAFA;
  border: none;
}

.daterangepicker.ltr .left .daterangepicker_input,
.daterangepicker.ltr .right .daterangepicker_input {
  padding: 0;
}

.daterangepicker .daterangepicker_input .input-mini {
  border: none;
  color: #26A599;
  margin: 0;
  padding: 0;
  text-align: center;
  background: transparent;
}

.daterangepicker .daterangepicker_input i.fa-calendar {
  display: none;
}

.daterangepicker .calendar-table thead th {
  color: #999999;
}

.daterangepicker td,
.daterangepicker th {
  min-height: 32px;
  height: 32px;
}

.daterangepicker .calendar-table thead th.next,
.daterangepicker .calendar-table thead th.prev {
  color: #333;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  text-decoration: none;
}

.daterangepicker select.monthselect:focus,
.daterangepicker select.yearselect:focus {
  outline: none;
}

.daterangepicker select.yearselect {
  padding-left: 2%;
  margin: 0;
}

.calendar-table .off.available {
  visibility: hidden;
}

.daterangepicker td.active {
  background-color: #26A599;
}

.daterangepicker td.start-date {
  border-radius: 2px 0 0 2px;
}

.daterangepicker td.end-date {
  border-radius: 0 2px 2px 0;
}

.daterangepicker td.in-range {
  background-color: #7ccbc4;
  color: #fff;
}

.daterangepicker td.start-date,
.daterangepicker td.end-date,
.daterangepicker td.start-date:hover,
.daterangepicker td.end-date:hover,
.daterangepicker td.in-range:hover {
  background-color: #26A599;
  color: white;
}

.daterangepicker .ranges li.active {
  background-color: #1aa599;
}

div.daterangepicker.ltr.show-ranges.show-calendar.opensleft > div.drp-buttons > button.applyBtn.btn.btn-sm.btn-primary {
  background-color: #1aa599;
  border-color: #1aa599;
}

div.daterangepicker.ltr.show-calendar.opensleft.drop-up > div.drp-buttons > button.applyBtn.btn.btn-sm.btn-primary {
  background-color: #1aa599;
  border-color: #1aa599;
}

/*.daterangepicker.ltr .ranges {*/
/*float: none;*/
/*width: 100%;*/
/*margin: 0;*/
/*padding: 0;*/
/*}*/
/*.daterangepicker.ltr .ranges .range_inputs {*/
/*display: -webkit-box;*/
/*display: -ms-flexbox;*/
/*display: flex;*/
/*-webkit-box-pack: justify;*/
/*-ms-flex-pack: justify;*/
/*justify-content: space-between;*/
/*width: 100%;*/
/*-webkit-box-orient: vertical;*/
/*-webkit-box-direction: reverse;*/
/*-ms-flex-direction: row-reverse;*/
/*flex-direction: row-reverse;*/
/*}*/
.range_inputs button.applyBtn,
.range_inputs button.cancelBtn {
  background: none;
  padding: 8px 15px;
  border: none;
  color: #27a499;
}

.range_inputs button.applyBtn {
  text-align: right;
  text-transform: uppercase;
}

.range_inputs button.cancelBtn {
  text-align: left;
}

.range_inputs button.applyBtn:hover,
.range_inputs button.cancelBtn:hover {
  text-decoration: underline;
}

/*day filter */
.wrapper-range-period::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  border-bottom: 1px solid #999;
  z-index: 0;
}

#range_selector_group .btn-range-period {
  background: transparent;
  color: #999;
}

#range_selector_group .btn-range-period:hover {
  color: #999;
}

#range_selector_group .btn-range-period.active {
  color: #26A599;
}

.btn-range-period.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  width: calc(100% + 2px);
  left: -1px;
  right: 0;
  border-bottom: 2px solid #26A599;
  z-index: 1;
}

#range_selector_group .border-on-hover {
  position: absolute;
  border: none;
  margin: 0;
  border-bottom: 2px solid #26A599;
  z-index: 99;
  bottom: 0;
  height: auto;
}

.btn-range-period,
#range_selector_group .border-on-hover {
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.stock-total {
  display: flex;
  justify-content: flex-end;
}

/* modal */
.form_field_name {
  font-size: 25px;
}

.form_field_code {
  font-size: 25px;
  text-align: center;
}

#editmap {
  height: 450px;
  width: 450px;
}

#showmoreViewModal > div > div > div.modal-header, #showbrandsViewModal > div > div > div.modal-header, #previewViewModal > div > div > div.modal-header {
  background-color: black;
  color: white;
}

#showmoreViewModal > div > div > div.modal-header > button > span,
#showbrandsViewModal > div > div > div.modal-header > button > span,
#previewViewModal > div > div > div.modal-header > button > span {
  color: white;
}

#showmoreViewModal > div > div > div.modal-header > button,
#showbrandsViewModal > div > div > div.modal-header > button,
#previewViewModal > div > div > div.modal-header > button {
  opacity: 1;
}

.modal-charts {
  width: 80%;
  max-width: 1200px;
}

.data-range-chart {
  position: absolute;
  right: 37px;
  font-size: 20px;
}

.btn-charts-mobile {
  font-size: 22px;
  line-height: 0;
}

.btn_get_tracking {
  cursor: pointer;
  background-color: transparent;
  margin-left: 5px;
  font-size: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid #333;
  color: #333;
  width: 20px;
  height: 20px;
}

.list-item-tracking {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock-tooltip-total-redcol {
  background: lightblue;
}

.vhr {
  border-left: 1px solid #dedede;
  width: 1px;
  height: 30.8px;
  display: inline-block;
  top: 4px;
  margin-left: 25px;
  margin-right: 25px;
}

@media all and (min-width: 1024px) {
  .filter-mobile,
  #date-picker-mobile {
    display: none;
  }
}

@media all and (max-width: 3200px) {
  .group_breadcrumb {
    display: none;
  }
  .dataTables_wrapper {
    overflow-x: scroll;
  }
}

@media all and (max-width: 1023px) {
  .play-button-wrapper {
    display: none;
  }
  .filter-desktop {
    display: none;
  }
  .wrapper-range-period::after {
    display: none;
  }
  .btn-range-period.active::after {
    display: none;
  }
  .range-period-container {
    width: 60%;
    margin: 20px auto 30px;
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #333;
  }
  .slick-dots li.slick-active button:before {
    color: #26A599;
  }
  .slide > .btn-range-period input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  #range_selector_group .slide.slick-slide.slick-current.slick-active .btn-range-period,
  #range_selector_group .custom-date-slide.slick-slide.slick-current.slick-active .btn-range-period button.range_selector {
    color: #26A599;
    background: transparent;
    border: none;
    margin: 0;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-slide {
    height: 100%;
  }
  .slick-slider .slick-dots {
    left: 0;
    right: 0;
    bottom: -30px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-slide label.btn {
    border: none;
    padding: 0;
    margin: 0;
  }
  .daterangepicker.show-calendar .calendar.right {
    display: none;
  }
  #date-picker-mobile {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: #26A599;
    color: #fff;
    text-align: center;
    z-index: 999;
    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  #date-picker-mobile:hover,
  #date-picker-mobile:focus {
    background-color: #228c82;
    -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  }
  #date-picker-mobile i {
    margin-top: -2px;
  }
  .daterangepicker.dropdown-menu {
    margin-right: 12px;
    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  .daterangepicker.ltr .left .daterangepicker_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-date-separator {
    font-weight: bold;
    color: #26a599;
  }
  .daterangepicker .input-mini {
    width: 48%;
  }
}

@media all and (max-width: 1199px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline-block;
  }
  .custom-popover {
    max-width: 100%;
    overflow: scroll;
  }
  .group_data_range {
    text-align: center;
  }
  .daterangepicker td,
  .daterangepicker th {
    min-height: 30px;
    height: 30px;
  }
  /* .daterangepicker table.table-condensed {
        display: none;
    } */
  .daterangepicker select.monthselect {
    width: 55%;
  }
  .daterangepicker select.yearselect {
    width: 45%;
  }
  .range-period-container {
    margin-top: 0px;
  }
  .bg-absolute {
    height: calc(100vh - 46px);
  }
  #admintable_length,
  #admintable_filter {
    display: none;
  }
}

@media all and (max-width: 991px) {
  .modal-charts {
    width: 100%;
    margin: 0;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .data-range-chart {
    position: relative;
    width: 100%;
    text-align: center;
    right: unset;
  }
  .modal-charts .modal-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}

@media all and (max-width: 450px) {
  #stock-results,
  #stock-pagination,
  #crm-results {
    margin-top: 20px;
  }
  .daterangepicker .calendar {
    padding: 0;
  }
  body > div.daterangepicker.ltr.show-calendar.opensleft.drop-up > div.drp-buttons > span {
    font-size: 17px;
    margin-bottom: 14px;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 28px;
  }
  .range-period-container {
    width: 75%;
  }
  .card-deck .card {
    margin-bottom: 21px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  a.btn.float-right.spacingButtonsFilterTop {
    margin-right: 10px;
  }
  .btn-toggle {
    margin: 0 4rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1.5rem;
    color: #6b7381;
    background: #909090;
  }
  .btn-toggle:focus,
  .btn-toggle.focus,
  .btn-toggle:focus.active,
  .btn-toggle.focus.active {
    outline: none;
  }
  .btn-toggle:before,
  .btn-toggle:after {
    line-height: 1.5rem;
    width: 4rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle:before {
    content: 'Only VIP';
    left: -4rem;
  }
  .btn-toggle:after {
    content: 'Show All';
    right: -4rem;
    opacity: 0.5;
  }
  .btn-toggle > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.active > .handle {
    left: 1.6875rem;
    transition: left 0.25s;
  }
  .btn-toggle.active:before {
    opacity: 0.5;
  }
  .btn-toggle.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-sm:before,
  .btn-toggle.btn-sm:after {
    line-height: 0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.4125rem;
    width: 2.325rem;
  }
  .btn-toggle.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-xs:before,
  .btn-toggle.btn-xs:after {
    display: none;
  }
  .btn-toggle:before,
  .btn-toggle:after {
    color: #6b7381;
  }
  .btn-toggle.active {
    background-color: #29b5a8;
  }
  .btn-toggle.btn-lg {
    margin: 0 5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 2.5rem;
    width: 5rem;
    border-radius: 2.5rem;
  }
  .btn-toggle.btn-lg:focus,
  .btn-toggle.btn-lg.focus,
  .btn-toggle.btn-lg:focus.active,
  .btn-toggle.btn-lg.focus.active {
    outline: none;
  }
  .btn-toggle.btn-lg:before,
  .btn-toggle.btn-lg:after {
    line-height: 2.5rem;
    width: 5rem;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle.btn-lg:before {
    content: 'Only VIP';
    left: -5rem;
  }
  .btn-toggle.btn-lg:after {
    content: 'Show All';
    right: -5rem;
    opacity: 0.5;
  }
  .btn-toggle.btn-lg > .handle {
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 1.875rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.btn-lg.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.btn-lg.active > .handle {
    left: 2.8125rem;
    transition: left 0.25s;
  }
  .btn-toggle.btn-lg.active:before {
    opacity: 0.5;
  }
  .btn-toggle.btn-lg.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-lg.btn-sm:before,
  .btn-toggle.btn-lg.btn-sm:after {
    line-height: 0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.6875rem;
    width: 3.875rem;
  }
  .btn-toggle.btn-lg.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-lg.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-lg.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-lg.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-lg.btn-xs:before,
  .btn-toggle.btn-lg.btn-xs:after {
    display: none;
  }
  .btn-toggle.btn-sm {
    margin: 0 0.5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 6rem;
    border-radius: 1.5rem;
  }
  .btn-toggle.btn-sm:focus,
  .btn-toggle.btn-sm.focus,
  .btn-toggle.btn-sm:focus.active,
  .btn-toggle.btn-sm.focus.active {
    outline: none;
  }
  .btn-toggle.btn-sm:before,
  .btn-toggle.btn-sm:after {
    line-height: 1.5rem;
    width: 0.5rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.55rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle.btn-sm:before {
    content: 'Only VIP';
    left: -0.5rem;
  }
  .btn-toggle.btn-sm:after {
    content: 'Show All';
    right: -0.5rem;
    opacity: 0.5;
  }
  .btn-toggle.btn-sm > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.btn-sm.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.btn-sm.active > .handle {
    left: 4.6875rem;
    transition: left 0.25s;
  }
  .btn-toggle.btn-sm.active:before {
    opacity: 0.5;
  }
  .btn-toggle.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-sm.btn-sm:before,
  .btn-toggle.btn-sm.btn-sm:after {
    line-height: 0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 1.9125rem;
    width: 2.325rem;
  }
  .btn.active.btn-toggle.btn-sm.btn-sm:before,
  .btn.active.btn-toggle.btn-sm.btn-sm:after {
    left: 0.9125rem;
  }
  .btn-toggle.btn-sm.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-sm.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-sm.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-sm.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-sm.btn-xs:before,
  .btn-toggle.btn-sm.btn-xs:after {
    display: none;
  }
  .btn-toggle.btn-xs {
    margin: 0 0;
    padding: 0;
    position: relative;
    border: none;
    height: 1rem;
    width: 2rem;
    border-radius: 1rem;
  }
  .btn-toggle.btn-xs:focus,
  .btn-toggle.btn-xs.focus,
  .btn-toggle.btn-xs:focus.active,
  .btn-toggle.btn-xs.focus.active {
    outline: none;
  }
  .btn-toggle.btn-xs:before,
  .btn-toggle.btn-xs:after {
    line-height: 1rem;
    width: 0;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle.btn-xs:before {
    content: 'Only VIP';
    left: 0;
  }
  .btn-toggle.btn-xs:after {
    content: 'Show All';
    right: 0;
    opacity: 0.5;
  }
  .btn-toggle.btn-xs > .handle {
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.75rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.btn-xs.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.btn-xs.active > .handle {
    left: 1.125rem;
    transition: left 0.25s;
  }
  .btn-toggle.btn-xs.active:before {
    opacity: 0.5;
  }
  .btn-toggle.btn-xs.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-xs.btn-sm:before,
  .btn-toggle.btn-xs.btn-sm:after {
    line-height: -1rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.275rem;
    width: 1.55rem;
  }
  .btn-toggle.btn-xs.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-xs.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-xs.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-xs.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-xs.btn-xs:before,
  .btn-toggle.btn-xs.btn-xs:after {
    display: none;
  }
  .btn-toggle.btn-secondary {
    color: #6b7381;
    background: #909090;
  }
  .btn-toggle.btn-secondary:before,
  .btn-toggle.btn-secondary:after {
    color: #6b7381;
  }
  .btn-toggle.btn-secondary.active {
    background-color: #ff8300;
  }
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  .btn-collapse {
    position: relative;
    top: -13px;
    width: 200px;
    height: 20px;
    background-color: #777;
    color: white;
    border-radius: 20px;
    opacity: 0.85;
  }
  .btn-collapse:hover {
    transition: background-color 0.5s ease;
    background-color: #2c7de0;
  }
  .btn-collapse span {
    position: relative;
    top: -11px;
    font-weight: bold;
    font-size: 13px;
  }
  .collapseContainer {
    text-align: center;
  }
  .collapseRequests {
    display: none;
    position: relative;
    top: -8px;
  }
  .icon7b {
    width: 38px;
    margin-left: 10px;
  }
  .custom-file-input {
    cursor: pointer;
  }
}

.containerBreadCrumb {
  display: flex;
  flex-direction: column;
  height: 75px;
  width: 100%;
  min-width: 480px;
  margin-top: 75px;
}

.containerBreadCrumb button.btn-secondary:hover {
  background-color: white !important;
  border-color: inherit;
  color: #506a73;
}

.breadcrumbOms {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  width: 100%;
  height: 85px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background-color: #fff;
  font-size: 14px;
  border: 1px solid #ccc;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}

.breadcrumb__title {
  font-size: 19px;
  text-align: center;
}

.breadcrumb__inner {
  margin: 0 auto;
  line-height: 50px;
}

.breadcrumbOms a {
  position: relative;
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  margin: auto;
  height: 100%;
  padding-left: 38px;
  padding-right: 0;
  color: #666;
}

.breadcrumbOms a:first-child {
  padding-left: 15.2px;
}

.breadcrumbOms a:last-child {
  padding-right: 15.2px;
}

.breadcrumbOms a:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 85px;
  height: 121px;
  top: -15px;
  right: -28.1481481481px;
  background-color: #fff;
  border-top-right-radius: 5px;
  -webkit-transform: scale(0.707) rotate(45deg);
  transform: scale(0.707) rotate(45deg);
  box-shadow: 1px -1px rgba(0, 0, 0, 0.25);
  z-index: -1;
}

.breadcrumbOms a:last-child:after {
  content: none;
}

.breadcrumbOms__inner {
  display: flex;
  flex-direction: column;
  margin: auto;
  z-index: 2;
}

.breadcrumbOms__title {
  font-weight: bold;
}

.breadcrumbOms a.active {
  background: #506a73;
  color: white;
}

.breadcrumbOms a.active:after {
  background: #506a73;
  color: white;
  z-index: -1;
}

@media all and (max-width: 767px) {
  .breadcrumbOms {
    font-size: 12px;
    height: 38px;
  }
  .breadcrumbOms a {
    padding-left: 25.333px;
  }
  .breadcrumbOms a:after {
    content: "";
    width: 38px;
    height: 38px;
    right: -19px;
    top: -2px;
    -webkit-transform: scale(0.707) rotate(45deg);
    transform: scale(0.707) rotate(45deg);
  }
}

.stocks-table td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;
}

.tbl-history-sms {
  padding: 10px !important;
  width: 100% !important;
  display: none;
  word-break: break-all;
}

.switch-mark-sent {
  position: absolute;
  top: 30%;
  left: 20%;
}

.fix-th {
  width: 120px !important;
}

.header-table-omni-sellers {
  display: table-cell;
  text-align: center;
  padding: 0 4px;
}

.body-value-refund {
  color: #ff1919;
  font-size: 12px;
  width: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.body-value-recovered {
  color: #3859f8;
  font-size: 12px;
  width: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.font-s-11 {
  font-size: 11px;
}

.font-s-12 {
  font-size: 12px;
}

.font-s-13 {
  font-size: 13px;
}

.font-s-14 {
  font-size: 14px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes wink {
  0%,
  50%,
  100% {
    margin-left: 0px;
  }
  25%,
  75% {
    margin-left: 10px;
  }
}

@keyframes wink {
  0%,
  50%,
  100% {
    margin-left: 0px;
  }
  25%,
  75% {
    margin-left: 10px;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.accordion-retail-sellers .card-body {
  overflow-x: auto;
}

.accordion-retail-sellers .card-header {
  padding: .3rem 1.25rem;
}

.accordion-retail-sellers .card-header .card-link {
  color: #000;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
}

@media screen and (max-width: 1920px) {
  .accordion-retail-sellers .card-header .card-link {
    font-size: calc(12px + (17 - 12) * ((100vw - 320px) / (1920 - 320)));
  }
}

@media screen and (max-width: 1920px) {
  .accordion-retail-sellers .card-header .card-link {
    flex-wrap: wrap;
  }
}

.accordion-retail-sellers .panel-collapse .card-body__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: .2rem;
}

.accordion-retail-sellers .panel-collapse .card-body__item:last-child {
  margin-bottom: 0;
}

.accordion-retail-sellers .card-header p {
  margin-bottom: 0;
}

.accordion-retail-sellers .card-header .store-name {
  flex: 1 1 auto;
  font-weight: 400;
}

@media screen and (max-width: 1920px) {
  .accordion-retail-sellers .card-header .store-name {
    width: 55%;
  }
}

@media screen and (min-width: 1921px) {
  .accordion-retail-sellers .card-header .store-revenues {
    border-right: 1px solid #d8d8d8;
    padding-right: 1rem;
    width: 12%;
    text-align: end;
    font-weight: 400;
  }
}

@media screen and (max-width: 1920px) {
  .accordion-retail-sellers .card-header .store-revenues {
    width: 45%;
    text-align: end;
    font-weight: 400;
  }
}

.accordion-retail-sellers .card-header .text-store-revenues-last-year {
  width: 20%;
  min-width: 100px;
  text-align: right;
}

@media screen and (max-width: 1920px) {
  .accordion-retail-sellers .card-header .text-store-revenues-last-year {
    width: 45%;
    order: 1;
    margin-top: 5px;
  }
}

.accordion-retail-sellers .card-header .text-store-revenues-last-year .text-last-year {
  display: block;
  font-size: 12px;
}

@media screen and (max-width: 561px) {
  .accordion-retail-sellers .card-header .text-store-revenues-last-year .text-last-year {
    font-size: 10px;
  }
}

.accordion-retail-sellers .card-header .text-store-revenues-last-year .text-last-year-to-date {
  display: block;
  font-size: 12px;
}

.accordion-retail-sellers .card-header .text-store-revenues-last-year .text:last-child:after {
  content: '';
  display: none;
}

.accordion-retail-sellers .card-header .store-revenues-brands-wrapper {
  width: 35%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1920px) {
  .accordion-retail-sellers .card-header .store-revenues-brands-wrapper {
    width: 55%;
    justify-content: flex-start;
    order: 1;
    margin-top: 5px;
  }
}

.accordion-retail-sellers .card-header .store-revenues-brands {
  font-size: 12px;
}

@media screen and (max-width: 561px) {
  .accordion-retail-sellers .card-header .store-revenues-brands {
    font-size: 10px;
  }
}
